module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"fr"}],"fields":[{"name":"title","store":true,"attributes":{"boost":10}},{"name":"processed","store":true,"attributes":{"boost":2}},{"name":"type","store":true},{"name":"typeName","store":true,"attributes":{"boost":5}},{"name":"url","store":true,"attributes":{"boost":1}},{"name":"taxo","store":true,"attributes":{"boost":5}},{"name":"address1","store":true,"attributes":{"boost":3}},{"name":"address2","store":true,"attributes":{"boost":3}},{"name":"ville","store":true,"attributes":{"boost":3}},{"name":"codePostal","store":true,"attributes":{"boost":3}}],"resolvers":{"node__carnet_d_adresse":{},"node__actualites":{},"node__evenements":{},"node__page":{},"node__teleformulaires":{},"node__formulairepaiement":{}},"filename":"search_index.json"},
    },{
      plugin: require('../../node_modules/gatsby-theme-blank/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"557","matomoUrl":"https://web-suivis.ternum-bfc.fr","siteUrl":"https://0000003937.web.ternum-bfc.fr","requireConsent":true},
    }]
